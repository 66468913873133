import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Input, message, Dropdown, Menu, notification, Space, Tooltip, Popconfirm, Tag } from 'antd';
import { ExportOutlined,HomeOutlined,EyeOutlined, PrinterOutlined,ArrowLeftOutlined, ArrowRightOutlined ,EditOutlined, PlusCircleOutlined,DeleteOutlined} from '@ant-design/icons';
import { getInspection } from '../../services/batimentService';
import InstructionForm from './instructionForm/InstructionForm';
import InstructionsDetail from './instructionsDetail/InstructionsDetail';

const { Search } = Input;

const Instructions = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleEyes, setIsModalVisibleEyes] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [idInspection, setIdInspection] = useState('');
  const scroll = { x: 400 };

  const handleEdit = (record) => {
    message.info(`Modifier inspection: ${record}`);
    setIdInspection(record)
    setIsModalVisible(true);
  };

  const handleViewDetails = (id) => {
    setIsModalVisibleEyes(true);
    setIdInspection(id)
  }
  const handleDelete = async (id) => {
    try {
/*       await deletePutDepartement(id); */
      setData(data.filter((item) => item.id_inspection !== id));
      message.success('Corps metier a ete supprimé avec succès');
    } catch (error) {
      notification.error({
        message: 'Erreur de suppression',
        description: 'Une erreur est survenue lors de la suppression du client.',
      });
    }
  };

    const fetchData = async () => {
      try {
        const { data } = await getInspection();
        setData(data);
        setLoading(false);
      } catch (error) {
        notification.error({
          message: 'Erreur de chargement',
          description: 'Une erreur est survenue lors du chargement des données.',
        });
        setLoading(false);
      }
    };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddInstruction = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalVisibleEyes(false)
  };

  const handleExportExcel = () => {
    message.success('Exporting to Excel...');
  };

  const handleExportPDF = () => {
    // Logic to export data to PDF
    message.success('Exporting to PDF...');
  };

  const handlePrint = () => {
    window.print();
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={handleExportExcel}>
        Export to Excel
      </Menu.Item>
      <Menu.Item key="2" onClick={handleExportPDF}>
        Export to PDF
      </Menu.Item>
    </Menu>
  );

  const columns = [
    { 
      title: '#', 
      dataIndex: 'id', 
      key: 'id', 
      render: (text, record, index) => index + 1, 
      width: "3%" 
    },
    { 
      title: 'Batiment', 
      dataIndex: 'nom_batiment', 
      key: 'nom_batiment',
      render: text => (
        <Space>
          <Tag icon={<HomeOutlined />} color='cyan'>{text}</Tag>
        </Space>
      ),
    },
    { 
        title: 'Commentaire', 
        dataIndex: 'commentaire', 
        key: 'commentaire',
        render: text => (
          <Space>
            <Tag color='cyan'>{text}</Tag>
          </Space>
        ),
      },
      { 
        title: 'Cat', 
        dataIndex: 'id_cat_instruction', 
        key: 'id_cat_instruction',
        render: text => (
          <Space>
            <Tag color='cyan'>{text}</Tag>
          </Space>
        ),
      },
      {
        title: 'Type',
        dataIndex: 'nom_type_instruction',
        key: 'nom_type_instruction',
        render: text => {
          let icon = null;
          let color = 'default';
    
          if (text === 'Avant') {
            icon = <ArrowLeftOutlined />;
            color = 'blue'; 
          } else if (text === 'Après') {
            icon = <ArrowRightOutlined />;
            color = 'green'; 
          }
    
          return (
            <Space>
              <Tag color={color}>
                {icon} {text}
              </Tag>
            </Space>
          );
        }
      },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (text, record) => (
        <Space size="middle">
            <Tooltip title="Voir les détails">
              <Button
                icon={<EyeOutlined />}
                onClick={() => handleViewDetails(record.id_inspection)}
                aria-label="Voir les détails de la tâche"
                style={{ color: 'blue' }}
              />
            </Tooltip>
           <Tooltip title="Modifier">
            <Button
              icon={<EditOutlined />}
              style={{ color: 'green' }}
              onClick={() => handleEdit(record.id_inspection)}
              aria-label="Edit department"
            />
          </Tooltip>
          <Tooltip title="Supprimer">
            <Popconfirm
              title="Etes-vous sûr de vouloir supprimer ce département ?"
              onConfirm={() => handleDelete(record.id_inspection)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                style={{ color: 'red' }}
                aria-label="Delete department"
              />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const filteredData = data.filter(item =>
    item.commentaire?.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <>
      <div className="client">
        <div className="client-wrapper">
          <div className="client-row">
            <div className="client-row-icon">
                📝
            </div>
            <h2 className="client-h2">Liste des inspections</h2>
          </div>
          <div className="client-actions">
            <div className="client-row-left">
              <Search placeholder="Recherche..." 
                enterButton 
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div className="client-rows-right">
               <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={handleAddInstruction}
              >
                Inspection
              </Button>
              <Dropdown overlay={menu} trigger={['click']} className='client-export'>
                <Button icon={<ExportOutlined />}>Export</Button>
              </Dropdown>
              <Button
                icon={<PrinterOutlined />}
                onClick={handlePrint}
                className='client-export'
              >
                Print
              </Button>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={{ pageSize: 10 }}
            rowKey="key"
            bordered
            size="middle"
            scroll={scroll}
            loading={loading}
          />
        </div>
      </div>

      <Modal
        title=""
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={700}
        centered
      >
        <InstructionForm idBatiment={''} closeModal={handleCancel}/>
      </Modal>
      <Modal
        title=""
        visible={isModalVisibleEyes}
        onCancel={handleCancel}
        footer={null}
        width={900}
        centered
      >
        <InstructionsDetail idInspection={idInspection}/>
      </Modal>
    </>
  );
};

export default Instructions;
